import React, { useEffect, useState } from "react";

// Chakra imports
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Cards from "./components/Cards";
import IconBox from "components/icons/IconBox";
import { Icon, useColorModeValue } from "@chakra-ui/react";
import { GiTakeMyMoney } from "react-icons/gi";

import Card from "components/card/Card.js";

// Assets
import TransactionsOverview from "./components/TransactionsView";
import { connect, useDispatch } from "react-redux";
import { fetchTransactionsRequest } from "redux/actions";
import { fetchTotalAmountRequest } from "redux/actions";
import Loading from "components/loading";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { GrTransaction } from "react-icons/gr";

const Transactions = ({
  transactions,
  loading,
  totalAmount,
  totalTransaction,
}) => {
  console.log(transactions)
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const limit = 10;
  const maxPage = Math.ceil(totalTransaction / limit);

  useEffect(() => {
    dispatch(fetchTransactionsRequest({ page, limit }));
    dispatch(fetchTotalAmountRequest());
  }, [dispatch, page, limit]);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "secondaryGray.600";
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <>
          <Flex direction={{ base: "column", lg: "row" }} gap={4}>
            <Flex direction="row" w={{ base: "100%", lg: "30%" }}>
              <Card>
                <Flex gap={4} alignItems='center'>
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={
                      <Icon
                        w="35px"
                        h="35px"
                        as={GrTransaction}
                        color={brandColor}
                      />
                    }
                  />
                  <Flex direction="column">
                    <Text color={textColorSecondary} fontSize="xs">
                      Total des transactions
                    </Text>
                    <Text color={textColor} fontSize="xl" fontWeight="700">
                      {totalTransaction}
                    </Text>
                  </Flex>
                </Flex>
              </Card>
            </Flex>

            <Box w={{ base: "100%", lg: "30%" }}>
              {totalAmount?.map((total, index) => (
                <Card>
                  <Flex gap={4} alignItems='center'>
                    <IconBox
                      w="40px"
                      h="40px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="35px"
                          h="35px"
                          as={GiTakeMyMoney}
                          color={brandColor}
                        />
                      }
                    />
                    <Flex direction="column">
                      <Text color={textColorSecondary} fontSize="xs">
                        Montant total des transactions réussies
                      </Text>
                      <Text color={textColor} fontSize="xl" fontWeight="700">
                        {total.amount} XAF
                      </Text>
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Box>
          </Flex>
          <Flex
            flexDirection="column"
            gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
            mt={4}
          >
            <Card px="0px" mb="20px">
              <TransactionsOverview
                transactions={transactions}
                loading={loading}
              />
              <Box mt="4">
                <Button
                  leftIcon={<ChevronLeftIcon />}
                  isDisabled={page === 1}
                  onClick={() => handlePageClick(page - 1)}
                >
                  Préc
                </Button>
                <Button
                  ml="2"
                  isDisabled={page === maxPage}
                  onClick={() => handlePageClick(page + 1)}
                >
                  Suiv
                  <ChevronRightIcon />
                </Button>
              </Box>
            </Card>
          </Flex>
        </>
      )}
    </Box>
  );
};
const mapStateToProps = ({ DashboardReducer }) => ({
  transactions: DashboardReducer.transactions,
  totalAmount: DashboardReducer.totalAmount,
  totalTransaction: DashboardReducer.totalTransaction,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
});

export default connect(mapStateToProps)(Transactions);
