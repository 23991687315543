import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    IconButton,
    Avatar,
} from '@chakra-ui/react';
import { FaFileDownload } from "react-icons/fa";
import React from 'react';
import LogoOrange from '../../../../assets/img/CM_ORANGEMONEY.png'
import LogoMTN from '../../../../assets/img/CM_MTNMOBILEMONEY.png'
import moment from 'moment/moment';
import Invoice from '../../../../components/invoice/Invoice';
import { pdf } from '@react-pdf/renderer';

const UserDetails = ({ isOpen, onClose, selectedData }) => {
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const generatePdf = async () => {

        const invoice = (

            <Invoice
                invoiceNumber={selectedData.subscriptions[0]?.transaction_id || ''}
                operator={selectedData.subscriptions[0]?.operator || ''}
                purchaseDate={moment(selectedData.subscriptions[0]?.subscriptionDate).format('dddd D MMMM YYYY [à] HH[h]mm')}
                username={selectedData.name}
                transactionNumber={selectedData.phoneNumber}
                itemName={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].name : selectedData.subscriptions[0]?.productId?.name || ''}
                unitPrice={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].price : selectedData.subscriptions[0]?.productId?.price || 0}
                quantity={selectedData.subscriptions[0]?.productId?.quantity || 1}
                total={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].price : selectedData.subscriptions[0]?.productId?.price || 0}
            />
        );

        const pdfBlob = await pdf(invoice).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Facture_${selectedData.name}.pdf`;
        a.click();
    };

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size='6xl'
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody pb={6}>
                    <Flex justify="flex-start" mb={8}>
                        <Text fontSize={{ base: "12px", lg: '24px' }} fontWeight="semibold">
                            Historique des transactions
                        </Text>
                    </Flex>
                    <Flex>
                        <TableContainer w='100%'>
                            <Table size='md' variant='simple' color='gray.500' mb='24px'>
                                <Thead>
                                    <Tr>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Operateur
                                            </Flex>
                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Numero WhatsApp
                                            </Flex>
                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Id Transaction
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Achat
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Expiration
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>
                                                Produit acheté
                                            </Flex>

                                        </Th>
                                        <Th pe='10px' borderColor={borderColor}>
                                            <Flex
                                                justify="flex-start"
                                                align='center'
                                                fontSize={{ sm: "10px", lg: "12px" }}
                                                color='gray.400'>

                                            </Flex>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selectedData?.subscriptions.map((subscription, index) => (
                                        <Tr key={index}>
                                            <Td>{subscription.operator === 'Orange' ? (<Avatar src={LogoOrange} size="sm" />) : (<Avatar src={LogoMTN} size="sm" />)}</Td>
                                            <Td>{selectedData.phoneNumber}</Td>
                                            <Td>{subscription.transaction_id}</Td>
                                            <Td>{moment(subscription.subscriptionDate).format('YYYY-MM-DD')}</Td>
                                            <Td>{subscription.productType === 'service' ? moment(subscription.expirationDate).format('YYYY-MM-DD') : ''}</Td>
                                            <Td>{subscription.productId.name}</Td>
                                            <Td>
                                                {selectedData && (
                                                    <div style={{ display: 'none' }}>
                                                        <Invoice
                                                            invoiceNumber={selectedData.subscriptions[0]?.transaction_id || ''}
                                                            operator={selectedData.subscriptions[0]?.operator || ''}
                                                            purchaseDate={moment(selectedData.subscriptions[0]?.subscriptionDate).format('dddd D MMMM YYYY [à] HH[h]mm')}
                                                            username={selectedData.name}
                                                            transactionNumber={selectedData.phoneNumber}
                                                            itemName={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].name : selectedData.subscriptions[0]?.productId?.name || ''}
                                                            unitPrice={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].price : selectedData.subscriptions[0]?.productId?.price || 0}
                                                            total={selectedData.subscriptions[0]?.productId?.subservices.length > 0 ? selectedData.subscriptions[0]?.productId?.subservices[0].price : selectedData.subscriptions[0]?.productId?.price || 0}
                                                        />
                                                    </div>
                                                )}
                                                <IconButton
                                                    aria-label='Call Segun'
                                                    size='md'
                                                    background='transparent'
                                                    icon={<FaFileDownload />}
                                                    onClick={generatePdf}
                                                // isDisabled={downloadClicked}
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Flex>

                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose} colorScheme='green' variant='solid'>Fermer</Button>
                </ModalFooter>

            </ModalContent>
        </Modal>
    );
};

export default UserDetails;
