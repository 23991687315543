import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    Flex,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import moment from "moment/moment";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchEventsUsersRequest } from "redux/actions";

const EvenementDetails = ({
    isOpen,
    onClose,
    selectedData,
    userEvents,
    eventId,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedData?._id) {
            dispatch(fetchEventsUsersRequest(selectedData._id));
        }
    }, [selectedData]);

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size="2xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody pb={6} pt={6}>
                    <Tabs variant="enclosed">
                        <TabList>
                            <Tab>Evènement</Tab>
                            <Tab>Abonnés</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Flex justify="flex-start" mb={4}>
                                </Flex>
                                {selectedData && (
                                    <Flex direction="column" gap={8}>
                                        <Flex direction="column" gap={2}>
                                            <Text
                                                fontSize={{ base: "12px", lg: "24px" }}
                                                fontWeight="semibold"
                                            >
                                                {selectedData.name}
                                            </Text>
                                            <Text
                                                fontSize={{ base: "10px", lg: "14px" }}
                                                color="green"
                                            >
                                                {moment(selectedData.date).format("D MMMM YYYY")}-
                                                {moment(selectedData.time, "hh:mm")
                                                    .locale("fr")
                                                    .format("hh:mm")}
                                                -{selectedData.place}
                                            </Text>
                                        </Flex>
                                        <Text maxHeight={300} overflowY={"scroll"}>
                                            {selectedData.description}
                                        </Text>
                                        <Flex>
                                            <Flex gap={4} align='center'>
                                                <Text>Packs associés:</Text>
                                                <Flex flexWrap='wrap'>
                                                    {selectedData?.pack?.map((bundle, index) => (
                                                        <Flex
                                                            key={index}
                                                            bg='green'
                                                            p={2}
                                                            borderRadius='md'
                                                            marginRight={2}
                                                            color='white'
                                                        >
                                                            {bundle.name} {bundle.price}
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <Flex direction="column" gap={2}>
                                    {userEvents && userEvents.length > 0 ? (
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'left' }}>Nom</th>
                                                    <th style={{ textAlign: 'left' }}>Pack souscrit</th>
                                                    <th style={{ textAlign: 'left' }}>Date de souscription</th>
                                                    <th style={{ textAlign: 'left' }}>Ville</th>
                                                    <th style={{ textAlign: 'left' }}>Prix</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userEvents.map((ele, index) => (
                                                    <React.Fragment key={ele._id}>
                                                        {ele.participations.map((participation, index) => (
                                                            <tr key={index}>
                                                                {index === 0 && (
                                                                    <td rowSpan={ele.participations.length} style={{ fontWeight: 'bold' }}>
                                                                        {ele.fullname}
                                                                    </td>
                                                                )}
                                                                <td>{participation?.packId.name}</td>
                                                                <td>{moment(participation.subscriptionDate).format('D MMMM YYYY')}</td>
                                                                <td>{ele.city}</td>
                                                                <td>{participation?.packId.price}</td>
                                                            </tr>
                                                        ))}
                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : (
                                        <Text>Aucun abonné pour l'instant</Text>
                                    )}
                                </Flex>

                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>

                <ModalFooter>
                    <Button onClick={onClose} colorScheme="green" variant="solid">
                        Fermer
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

const mapStateToProps = ({ DashboardReducer }) => ({
    userEvents: DashboardReducer.userEvent,
    loading: DashboardReducer.loading,
    error: DashboardReducer.error,
});

export default connect(mapStateToProps)(EvenementDetails);
