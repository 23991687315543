import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  WrapItem,
  useDisclosure,
  Image,
  Icon,
} from "@chakra-ui/react";
import { MdAddCircle } from "react-icons/md";

// Custom components
import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import Card from "components/card/Card.js";
import TableTopCreators from "views/admin/marketplace/components/TableTopCreators";

import { connect, useDispatch } from "react-redux";
import { fetchProductsRequest } from "redux/actions";
import Loading from "components/loading";
import AddProductForm from "./components/AddProductForm";
import { BASE_URL_CLOUD } from "constants";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import IconBox from "components/icons/IconBox";
import { IoStorefrontSharp } from "react-icons/io5";

const Marketplace = ({ products, loading, totalProduct, totalService }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [page, setPage] = useState(1);
  const limit = 10;

  const openModal = (event) => {
    setSelectedProduct(event);
    setIsEditing(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    dispatch(fetchProductsRequest({ page, limit }));
  }, [dispatch, page, limit]);

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const filterType = (array = [], type) => {
    return array?.filter((ele) => ele?.type === type);
  };

  const maxPage = Math.ceil(totalProduct/limit);

  const handlePageClick = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const dataColor = useColorModeValue("navy.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const textColors = useColorModeValue("navy.700", "white");
  const textColorSecondary = "secondaryGray.600";

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Tabs ixsanual variant="enclosed" colorScheme="green">
        <TabList>
          <Tab style={{ fontWeight: "bold" }}>Produits</Tab>
          <Tab style={{ fontWeight: "bold" }}>Services</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Flex direction="row" w={{base:"100%", lg:"30%"}} mb={4}>
              <Card>
                <Flex gap={4} alignItems='center'>
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={
                      <Icon w="35px" h="35px" as={IoStorefrontSharp} color={brandColor} />
                    }
                  />
                  <Flex direction="column">
                    <Text color={textColorSecondary} fontSize="xs">Total des produits</Text>
                    <Text color={textColors} fontSize="xl" fontWeight="700">
                      {totalProduct}
                    </Text>
                  </Flex>
                </Flex>
              </Card>
            </Flex>

            <Card p="0px">
              <Flex
                align={{ xs: "flex-start", lg: "center" }}
                justify="space-between"
                w="100%"
                px="22px"
                py="18px"
              >
                <Text color={textColor} fontSize="xl" fontWeight="600">
                  Liste des produits
                </Text>
                <WrapItem>
                  <Button
                    rightIcon={<MdAddCircle />}
                    colorScheme="whatsapp"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    Ajouter
                  </Button>
                  <AddProductForm isOpen={isOpen} onClose={onClose} />
                </WrapItem>
              </Flex>
              {loading ? (
                <>
                  <Loading />
                </>
              ) : (
                <>
                  {filterType(products, "product")?.length === 0 ? (
                    <Flex
                      direction="column"
                      justifyContent="center"
                      align="center"
                      height={{ sm: "10rem", lg: "30rem" }}
                    >
                      <Image
                        src="https://bibemella.isomora.com/wp-content/uploads/2023/10/logo-fbe.png"
                        width={50}
                        height={50}
                        mb={4}
                      />
                      <Text color={dataColor} fontSize="sm">
                        Aucun produit disponible
                      </Text>
                    </Flex>
                  ) : (
                    <>
                      {filterType(products, "product")?.map(
                        (product, index) => (
                          <HistoryItem
                            key={index}
                            name={product.name}
                            image={BASE_URL_CLOUD + product.image}
                            price={product.price}
                            product={product}
                            setIsFormOpen={openModal}
                          />
                        )
                      )}
                      <Box mt="4">
                        <Button
                          leftIcon={<ChevronLeftIcon />}
                          isDisabled={page === 1}
                          onClick={() => handlePageClick(page - 1)}
                        >
                          Préc
                        </Button>
                        <Button
                          ml="2"
                          isDisabled={page === maxPage || maxPage < limit}
                          onClick={() => handlePageClick(page + 1)}
                        >
                          Suiv
                          <ChevronRightIcon />
                        </Button>
                      </Box>

                      {isEditing && (
                        <AddProductForm
                          isOpen={isEditing}
                          onClose={closeModal}
                          productData={selectedProduct}
                          productId={selectedProduct?._id}
                          isEditing={isEditing}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <Flex direction="row" w={{base:"100%", lg:"30%"}} mb={4}>
              <Card>
                <Flex gap={4} alignItems='center'>
                  <IconBox
                    w="40px"
                    h="40px"
                    bg={boxBg}
                    icon={
                      <Icon w="35px" h="35px" as={IoStorefrontSharp} color={brandColor} />
                    }
                  />
                  <Flex direction="column">
                    <Text color={textColorSecondary} fontSize="xs">Total des Services</Text>
                    <Text color={textColors} fontSize="xl" fontWeight="700">
                      {totalService}
                    </Text>
                  </Flex>
                </Flex>
              </Card>
            </Flex>
            <Card px="0px" mb="20px">
              <TableTopCreators
                products={products}
                loading={loading}
                limit={limit}
                totalService={totalService}
              />
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const mapStateToProps = ({ DashboardReducer }) => ({
  products: DashboardReducer.products,
  totalProduct: DashboardReducer.totalProduct,
  totalService: DashboardReducer.totalService,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
});

export default connect(mapStateToProps)(Marketplace);
