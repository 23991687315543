import React from 'react';
import { StyleSheet, Image, View } from '@react-pdf/renderer';
import { Text, Document, Page } from '@react-pdf/renderer';
import { Divider } from '@chakra-ui/react';
import logo from 'assets/img/BibemellaLogo.png';

// Styles for @react-pdf/renderer
const styles = StyleSheet.create({
    container: {
        padding: 20,
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
    logo: {
        width: 100,
        height: 100,
        marginRight: 20,
    },
    title: {
        fontSize: 30,
        fontWeight: 'bold',
    },
    divider: {
        borderBottom: 1,
        borderColor: 'black',
        marginBottom: 10,
    },
    section: {
        marginBottom: 10,
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: 10,
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    flexContainerAmount: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    flexItem: {
        flexDirection: 'column',
        flex: 1,
    },
    item: {
        fontWeight: 'bold'
    }
});

const InvoicePDF = ({
    invoiceNumber,
    operator,
    purchaseDate,
    username,
    transactionNumber,
    itemName,
    unitPrice,
    quantity,
    total,
}) => {
    return (
        <Document>
            <Page size="A4" style={styles.container}>
                <View style={styles.header}>
                    <Image style={styles.logo} src={logo} />
                    <Text style={styles.title}>Facture</Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.section}>Numéro de facture: {invoiceNumber}</Text>
                    <Text style={styles.section}>Opérateur: {operator}</Text>
                    <Text>Date d'achat: {purchaseDate}</Text>
                </View>
                <Divider style={styles.divider} />
                <View style={styles.section} >
                    <Text style={styles.sectionTitle}>Client</Text>
                    <Text style={styles.section}>Utilisateur: {username}</Text>
                    <Text>N° transaction: {transactionNumber}</Text>
                </View>
                <Divider style={styles.divider} />
                <View style={styles.section}>
                    <View style={styles.flexContainer}>
                        <View style={styles.flexItem}>
                            <Text style={styles.sectionTitle}>Description</Text>
                            <Text style={styles.item}>{itemName}</Text>
                        </View>
                        <View style={styles.flexItem}>
                            <Text style={styles.sectionTitle}>Quantité</Text>
                            <Text>{quantity}</Text>
                        </View>
                        <View style={styles.flexItem}>
                            <Text style={styles.sectionTitle}>Prix Unitaire</Text>
                            <Text>{unitPrice}</Text>
                        </View>
                    </View>
                </View>
                <Divider style={styles.divider} />
                <View style={styles.flexContainerAmount}>
                    <Text style={styles.sectionTitle}>Montant payé: </Text>
                    <Text>{total}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default InvoicePDF;
