// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import React from "react";
import { MdBarChart } from "react-icons/md";

function TotalSpent(props) {
  const { stats, ...rest } = props;

  const monthlyData = stats.monthlyData || {};
  const monthlyStatsArray = Object.entries(monthlyData).map(([monthYear, data]) => ({
    monthYear,
    ...data,
  }));

  const allMonths = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];

  // Utilisation des données mensuelles pour remplir lineChartDataTotalSpent
  const lineChartDataTotalSpent = [{
    name: "Revenus",
    data: allMonths.map(month => {
      const foundMonth = monthlyStatsArray.find(item => item.monthYear.startsWith(allMonths.indexOf(month) + 1));
      return foundMonth ? foundMonth.totalAmount : 0;
    }),
  }];

  const lineChartOptionsTotalSpent = {
    chart: {
      toolbar: {
        show: false,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: ["#4318FF", "#39B8FF"],
    markers: {
      size: 0,
      colors: "white",
      strokeColors: "#7551FF",
      strokeWidth: 3,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      type: "line",
    },
    xaxis: {
      type: "numeric", // Utilisez "category" au lieu de "numeric" pour les catégories
      categories: allMonths,
      labels: {
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    color: ["#7551FF", "#39B8FF"],
  };

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  return (
    <Card
      justifyContent='center'
      align='center'
      direction="column"
      w="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" align="center" w="100%" mt={8}>
        <Text
          color={textColor}
          fontSize={{ base: "20px", lg: "30px" }}
          textAlign="start"
          fontWeight="700"
          lineHeight="100%"
        >
          Statistiques des transactions mensuelles
        </Text>
        <Button
          ms="auto"
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Box minH="260px" w="100%" >
          <LineChart
            chartData={lineChartDataTotalSpent}
            chartOptions={{
              ...lineChartOptionsTotalSpent,
              width: "100%", // Ajuster la largeur du graphique pour qu'il occupe toute la largeur de la boîte
              height: "100%", // Ajuster la hauteur du graphique pour qu'il occupe toute la hauteur de la boîte
            }}
          />
        </Box>
      </Flex>
    </Card>
  );
}

export default TotalSpent;
