import {
    Flex,
    Image,
    Progress,
  } from "@chakra-ui/react";
  import React from "react";
  import logo from '../../assets/img/BibemellaLogo.png' 
  
  function Loading(props) {
    return (
        <Flex direction='column' justifyContent='center' align='center' height={{ sm: "10rem", lg: "30rem" }}>
        <Image src={logo} width={58} height={58} mb={4}/>
        <Progress size="xs" isIndeterminate />
      </Flex>
    );
  }
  
  export default Loading;
  