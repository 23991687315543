import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea,
    Stack,
    NumberInputField,
    NumberInput,
    IconButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { addServiceRequest } from 'redux/actions';
import { connect, useDispatch, useSelector } from "react-redux";
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { updateServiceRequest } from 'redux/actions';
import toast from 'react-hot-toast';
import { deleteSubserviceRequest } from 'redux/actions';

const AddServiceForm = ({ isOpen, onClose, serviceData }) => {
    let [name, setName] = useState('')
    let [nameService, setNameService] = useState('')
    let [price, setPrice] = useState('')
    let [durationInDay, setDurationInDay] = useState('')
    let [priceService, setPriceService] = useState('')
    let [durationInDayService, setDurationInDayService] = useState('')
    let [descriptionService, setDescriptionService] = useState('')
    let [description, setDescription] = useState('')
    const [sousType, setSousType] = useState('non');
    const [subservicesList, setSubservicesList] = useState([]);
    const [showSubserviceForm, setShowSubserviceForm] = useState(false);

    const loading = useSelector((state) => state.DashboardReducer.loading_action);
    const closeModal = useSelector((state) => state.DashboardReducer.closeModal);

    useEffect(() => {
        if (closeModal) {
            onClose();
        }
    }, [closeModal]);

    useEffect(() => {
        if (serviceData) {
            setName(serviceData.name || '');

            if (serviceData.hasSub) {
                setNameService(serviceData.subservices[0].name || '');
                setPriceService(serviceData.subservices[0].price || '');
                setDurationInDayService(serviceData.subservices[0].durationInDay || '');
                setDescriptionService(serviceData.subservices[0].description || '');
                setSubservicesList(serviceData.subservices || []);
                setShowSubserviceForm(true);
                setSousType('oui');
            } else {
                setDescription(serviceData.description || '');
                setPrice(serviceData.price || '');
                setDurationInDay(serviceData.durationInDay || '');
                setShowSubserviceForm(false);
                setSousType('non');
            }
        }
    }, [serviceData]);

    const dispatch = useDispatch();

    const handleRadioChange = (value) => {
        setSousType(value);
        setShowSubserviceForm(value === 'oui');
    };

    let handleNameChange = (e) => {
        let inputValue = e.target.value
        setName(inputValue)
    }
    let handleNameServiceChange = (e) => {
        let inputValue = e.target.value
        setNameService(inputValue)
    }
    const handlePriceChange = (valueString, valueNumber) => {
        setPrice(valueString);
    };
    const handleDurationInDayChange = (valueString, valueNumber) => {
        setDurationInDay(valueString);
    };
    const handlePriceServiceChange = (valueString, valueNumber) => {
        setPriceService(valueNumber);
    };
    const handleDurationInDayServiceChange = (valueString, valueNumber) => {
        setDurationInDayService(valueNumber);
    };
    let handleDescriptionChange = (e) => {
        let inputValue = e.target.value
        setDescription(inputValue)
    }
    let handleDescriptionServiceChange = (e) => {
        let inputValue = e.target.value
        setDescriptionService(inputValue)
    }
    const handleSubserviceChange = (index, field, value) => {
        const updatedSubservicesList = [...subservicesList];
        updatedSubservicesList[index] = {
            ...updatedSubservicesList[index],
            [field]: value,
        };
        setSubservicesList(updatedSubservicesList);
    };

    const handleRemoveSubservice = (index) => {
        const updatedSubservicesList = [...subservicesList];
        updatedSubservicesList.splice(index, 1);
        setSubservicesList(updatedSubservicesList);
    };

    const handleRemoveOnlineSubservice = (index) => {
        const updatedSubservicesList = [...subservicesList];
        updatedSubservicesList.splice(index, 1);
        setSubservicesList(updatedSubservicesList);
        dispatch(deleteSubserviceRequest({ id: serviceData?._id, optionId: serviceData?.subservices[index]._id }));
    };

    const handleRemove = (index) => {
        if (serviceData) {
            handleRemoveOnlineSubservice(index);
        } else {
            handleRemoveSubservice(index);
        }
    };


    const handleAddSubservice = () => {
        setSubservicesList([...subservicesList, {}]);
    };

    const handleAddService = (e) => {
        e.preventDefault();

        if (!sousType) {
            toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
            return
        }

        if (sousType === 'oui') {
            if (!nameService || !priceService || !durationInDayService || !descriptionService) {
                toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
                return;
            }
        } else {
            if (!name || !description || !price || !durationInDay) {
                toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
                return;
            }
        }

        let serviceData = {
            name: name,
            type: "service",
        };

        if (sousType === 'oui') {
            // If it's a subservice
            serviceData.hasSub = true;
            serviceData.subservices = [
                ...subservicesList,
                {
                    name: nameService,
                    price: priceService,
                    durationInDay: durationInDayService,
                    description: descriptionService,
                    category: name,
                }
            ];

            // Réinitialiser les champs du sous-service
            setNameService('');
            setPriceService('');
            setDurationInDayService('');
            setDescriptionService('');
        } else {
            serviceData.description = description;
            serviceData.price = price;
            serviceData.durationInDay = durationInDay;
        }

        dispatch(addServiceRequest(serviceData));

        setSubservicesList([]);
        setSousType('non');
        setDescription('');
        setPrice('');
        setDurationInDay('');

        setName(''); // Réinitialisez toujours le champ name indépendamment de sousType

    };

    const idProduct = serviceData ? serviceData._id : null;
    const handleUpdateService = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let serviceData = {
            name: name,
            type: "service",
        };

        if (sousType === 'oui') {
            // If it's a subservice
            serviceData.hasSub = true;
            serviceData.subservices = [
                ...subservicesList,
                {
                    name: nameService,
                    price: priceService,
                    durationInDay: durationInDayService,
                    description: descriptionService,
                    category: name,
                }
            ];

            // Réinitialiser les champs du sous-service
            setNameService('');
            setPriceService('');
            setDurationInDayService('');
            setDescriptionService('');
        } else {
            serviceData.description = description;
            serviceData.price = price;
            serviceData.durationInDay = durationInDay;
        }

        dispatch(updateServiceRequest(idProduct, serviceData));

        setSubservicesList([]);
        setSousType('')
        setName('')
    };

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size='4xl'
            scrollBehavior={'inside'}
            isCentered

        >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent>
                <ModalBody pb={6}>
                    <Flex justify="flex-start" p={4}>
                        <Text fontSize={{ base: "12px", lg: '24px' }} fontWeight="semibold">
                            Ajouter un service
                        </Text>
                    </Flex>
                    <Flex direction='column' justify="flex-start" gap={4} p={4}>
                        <FormControl isRequired>
                            <FormLabel>Nom</FormLabel>
                            <Input
                                value={name}
                                onChange={handleNameChange}
                                variant='filled' placeholder='Product name' />
                        </FormControl>
                        <FormControl isRequired>
                            <Flex>
                                <FormLabel>Sous service ?</FormLabel>
                                <RadioGroup defaultValue='non' onChange={handleRadioChange} value={sousType}>
                                    <Stack spacing={5} direction='row'>
                                        <Radio colorScheme='green' value='oui'>Oui</Radio>
                                        <Radio colorScheme='green' value='non'>Non</Radio>
                                    </Stack>
                                </RadioGroup>
                            </Flex>
                        </FormControl>

                        {sousType === 'oui' && (
                            <>
                                {/* (serviceData?._id ? ) */}
                                {!serviceData?._id && showSubserviceForm && (
                                    <>
                                        <Flex key={-1} gap={4}>
                                            <FormControl isRequired>
                                                <FormLabel>Nom sous-service</FormLabel>
                                                <Input
                                                    value={nameService}
                                                    onChange={handleNameServiceChange}
                                                    placeholder="Entrez le nom du sous-service"
                                                />
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Prix</FormLabel>
                                                <NumberInput size='md' maxW={72} onChange={handlePriceServiceChange} value={priceService}>
                                                    <NumberInputField />
                                                </NumberInput>
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Durée en jours</FormLabel>
                                                <NumberInput size='md' maxW={60} onChange={handleDurationInDayServiceChange} value={durationInDayService}>
                                                    <NumberInputField />
                                                </NumberInput>
                                            </FormControl>

                                            <FormControl isRequired>
                                                <FormLabel>Description</FormLabel>
                                                <Textarea
                                                    value={descriptionService}
                                                    onChange={handleDescriptionServiceChange}
                                                    placeholder='Service description'
                                                    size='sm'
                                                    variant='filled'
                                                />
                                            </FormControl>

                                        </Flex>
                                    </>
                                )}

                                {subservicesList.map((subservice, index) => (
                                    <Flex key={index} gap={4}>
                                        <FormControl isRequired>
                                            <FormLabel>Nom sous-service</FormLabel>
                                            <Input
                                                value={subservice.name || ''}
                                                onChange={(e) => handleSubserviceChange(index, 'name', e.target.value)}
                                                placeholder="Entrez le nom du sous-service"
                                            />
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Prix</FormLabel>
                                            <NumberInput size='md' maxW={72} onChange={(value) => handleSubserviceChange(index, 'price', value)} value={subservice.price}>
                                                <NumberInputField />
                                            </NumberInput>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Durée en jours</FormLabel>
                                            <NumberInput size='md' maxW={60} onChange={(value) => handleSubserviceChange(index, 'durationInDay', value)} value={subservice.durationInDay}>
                                                <NumberInputField />
                                            </NumberInput>
                                        </FormControl>

                                        <FormControl isRequired>
                                            <FormLabel>Description</FormLabel>
                                            <Textarea
                                                value={subservice.description || ''}
                                                onChange={(e) => handleSubserviceChange(index, 'description', e.target.value)}
                                                placeholder='Service description'
                                                size='sm'
                                                variant='filled'
                                            />
                                        </FormControl>
                                        <Flex align='center' justify='center'>
                                            <IconButton
                                                isRound={true}
                                                variant='solid'
                                                colorScheme='red'
                                                aria-label='Done'
                                                fontSize='20px'
                                                icon={<DeleteIcon />}
                                                onClick={() => handleRemove(index)}
                                            />
                                        </Flex>

                                    </Flex>
                                ))}

                                <Flex justify='flex-end'>
                                    <IconButton
                                        isRound={true}
                                        variant='solid'
                                        colorScheme='green'
                                        aria-label='Done'
                                        fontSize='20px'
                                        icon={<AddIcon />}
                                        onClick={handleAddSubservice}
                                    />
                                </Flex>
                            </>
                        )}

                        {sousType === 'non' && (
                            <>
                                <Flex gap={4}>
                                    <FormControl isRequired>
                                        <FormLabel>Prix</FormLabel>
                                        <NumberInput size='md' maxW={72} onChange={handlePriceChange} value={price}>
                                            <NumberInputField />
                                        </NumberInput>
                                    </FormControl>

                                    <FormControl isRequired>
                                        <FormLabel>Durée en jours</FormLabel>
                                        <NumberInput size='md' maxW={60} onChange={handleDurationInDayChange} value={durationInDay}>
                                            <NumberInputField />
                                        </NumberInput>
                                    </FormControl>
                                    <FormControl isRequired>
                                        <FormLabel>Description</FormLabel>
                                        <Textarea
                                            value={description}
                                            onChange={handleDescriptionChange}
                                            placeholder='Service description'
                                            size='sm'
                                            variant='filled'
                                        />
                                    </FormControl>
                                </Flex>
                            </>
                        )}
                    </Flex>

                </ModalBody>

                <ModalFooter>
                    <Flex gap={4}>
                        <Button onClick={() => onClose()} colorScheme='red' variant='solid' >
                            Annuler
                        </Button>
                        <Button onClick={serviceData?._id ? handleUpdateService : handleAddService} colorScheme='green' variant='solid' isLoading={loading} loadingText='En cours...'>
                            {serviceData?._id ? 'Modifier' : 'Ajouter'}
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddServiceForm;
