import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Flex, Text, Image, Button, Stack, Box } from '@chakra-ui/react';
import addDropzone from 'assets/img/add.png'
import { deleteImageGalleryRequest } from 'redux/actions';
import { connect, useDispatch } from "react-redux";

const isValidImage = (image) => {
  // Ajoutez ici votre logique pour vérifier si l'objet image est valide
  return image instanceof Blob; // Exemple basique, ajustez selon vos besoins
};

const ImageGallery = ({ images, onAddImages, eventId, onDeleteImage }) => {
  const [selectedImages, setSelectedImages] = useState(images);

  useEffect(() => {
    // Mettez à jour les images sélectionnées lorsque la propriété images change
    setSelectedImages(images || []);
  }, [images]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const updatedImages = [...selectedImages, ...acceptedFiles];
      setSelectedImages(updatedImages);
      onAddImages(acceptedFiles); // Appeler la fonction parent avec le tableau mis à jour
    },
    [selectedImages, onAddImages]
  );

  const removeImage = (index) => {
    // Récupère l'URL de l'image à supprimer
    const imageUrl = images[index];
    // Utilisez la prop onDeleteImage pour gérer la suppression
    if (onDeleteImage) {
      onDeleteImage(imageUrl);
    }
  };

  const removeLocalImage = (index) => {
    const updatedImages = [...selectedImages];
    console.log(updatedImages)
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);
    onAddImages(updatedImages);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*', multiple: true });

  return (
    <Flex direction="column" align="center">
      <Flex spacing={4} wrap="wrap" justify="center">
        <Flex wrap="wrap" justify="center">
          {selectedImages.map((image, index) => (
            <Flex key={index} direction="column" align="center" m={2}>
              <Image src={isValidImage(image) ? URL.createObjectURL(image) : image} alt={`Preview ${index}`} boxSize="100px" objectFit="contain" />
              {onDeleteImage ? (
                <Button size="sm" mt={2} onClick={() => removeImage(index)}>
                  Retirer
                </Button>
              ) : (
                <Button size="sm" mt={2} onClick={() => removeLocalImage(index)}>
                  Retirer
                </Button>
              )}
            </Flex>
          ))}
        </Flex>
        <Box mt={2}>
          <div {...getRootProps()} style={{ cursor: 'pointer' }} >
            <input {...getInputProps()} />
            <img src={addDropzone} alt="add preview" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
          </div>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ImageGallery;
