import {
  Box,
  Flex,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import { MdBarChart } from "react-icons/md";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { connect, useDispatch } from "react-redux";
import Loading from "components/loading";
import { fetchStatsRequest } from "redux/actions";

function Reports({ loading, stats }) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStatsRequest());
  }, [dispatch]);

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {loading ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          // pt={{ base: "130px", md: "80px", xl: "80px" }}
          gap="20px"
          justifyContent="space-between"
        >
          <Flex
            flexDirection="column"
            width={{ base: "100%", lg: "25%" }}
            gap="15px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  display={{ base: "none", lg: "flex" }}
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="25px"
                      h="25px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Nombre total de transactions éffectuées"
              value={`${stats.totalAmount > 0 ? stats.totalAmount : 0}`}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  display={{ base: "none", lg: "flex" }}
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="25px"
                      h="25px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Nombre total d'utilisateurs"
              value={stats.totalUsers > 0 ? stats.totalUsers : 0}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  display={{ base: "none", lg: "flex" }}
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="25px"
                      h="25px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Nombre total de produits et services"
              value={`${stats.totalProductService > 0 ? stats.totalProductService : 0}`}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  display={{ base: "none", lg: "flex" }}
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="25px"
                      h="25px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Nombre total d'évènements"
              value={`${stats.totalEvent > 0 ? stats.totalEvent: 0}`}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  display={{ base: "none", lg: "flex" }}
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="25px"
                      h="25px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Nombre d'interactions avec le bot"
              value={`${stats.totalEngagementLevel > 0 ? stats.totalEngagementLevel : 0}`}
            />
          </Flex>

          <Flex width={{ base: "100%", lg: "75%" }}>
            <TotalSpent stats = {stats}/>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}

const mapStateToProps = ({ DashboardReducer }) => ({
  stats: DashboardReducer.stats,
  loading: DashboardReducer.loading,
  error: DashboardReducer.error,
});

export default connect(mapStateToProps)(Reports);
