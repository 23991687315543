import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Text,
    Textarea,
    NumberInput,
    NumberInputField,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import uploadImage from 'assets/img/upload.png'
import { useDropzone } from 'react-dropzone';
import { addProductRequest } from 'redux/actions';
import { BASE_URL_CLOUD } from 'constants';
import { updateProductRequest } from 'redux/actions';
import toast from 'react-hot-toast';

const AddProductForm = ({ isOpen, onClose, productData }) => {
    let [description, setDescription] = useState('')
    let [advantage, setAdvantage] = useState('')
    let [name, setName] = useState('')
    let [price, setPrice] = useState('')
    let [link, setLink] = useState('')
    const [preview, setPreview] = useState(null);
    const [previewFile, setPreviewFile] = useState(null);

    const loading = useSelector((state) => state.DashboardReducer.loading_action);
    const closeModal = useSelector((state) => state.DashboardReducer.closeModal);

    useEffect(() => {
        if (closeModal) {
            onClose();
        }
    }, [closeModal]);

    useEffect(() => {
        // Mettez à jour les champs du formulaire lorsque eventData change
        if (productData) {
            setName(productData.name || '');
            setPrice(productData.price || '');
            setDescription(productData.description || '');
            setAdvantage(productData.advantage || '');
            setLink(productData.link || '');
            if (productData.image) {
                setPreviewFile(BASE_URL_CLOUD + productData.image);
            }
        }
    }, [productData]);

    const dispatch = useDispatch();

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        const reader = new FileReader();

        reader.onload = function () {
            setPreviewFile(reader.result);

        }

        reader.readAsDataURL(file);

        setPreview(file);
    }, []);

    const { previewImage, getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop
    });

    let handleNameChange = (e) => {
        let inputValue = e.target.value
        setName(inputValue)
    }
    const handlePriceChange = (valueString, valueNumber) => {
        setPrice(valueNumber);
    };
    let handleDescriptionChange = (e) => {
        let inputValue = e.target.value
        setDescription(inputValue)
    }
    let handleAvantageChange = (e) => {
        let inputValue = e.target.value
        setAdvantage(inputValue)
    }
    let handleLinkChange = (e) => {
        let inputValue = e.target.value
        setLink(inputValue)
    }
    const handleAddProduct = (e) => {
        e.preventDefault();
        if (!name || !description || !price || !advantage || !link || !preview) {
            toast.error("Verifiez que vous avez rempli tout les champs obligatoires")
            return;
        }

        const productData = new FormData();
        productData.append('name', name);
        productData.append('description', description);
        productData.append('price', price);
        productData.append('type', 'product');
        productData.append('image', preview);
        productData.append('advantage', advantage);
        productData.append('link', link);

        dispatch(addProductRequest(productData));
        setDescription('');
        setAdvantage('');
        setName('');
        setPrice('');
        setLink('');
        setPreview(null);
        setPreviewFile(null)
    }

    const idProduct = productData ? productData._id : null;
    const handleUpdateProduct = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            const productData = new FormData();
            productData.append('name', name);
            productData.append('description', description);
            productData.append('price', price);
            productData.append('type', 'product');
            if (preview) {
                productData.append('image', preview);
            }
            productData.append('advantage', advantage);
            productData.append('link', link);

            dispatch(updateProductRequest(idProduct, productData));
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            size='4xl'
            scrollBehavior={'inside'}
            isCentered
        >
            <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
            />
            <ModalContent>
                <ModalBody pb={6}>
                    <Flex justify="flex-start" p={4}>
                        <Text fontSize={{ base: "12px", lg: '24px' }} fontWeight="semibold">
                            Ajouter un produit
                        </Text>
                    </Flex>
                    <Flex direction='column' justify="flex-start" gap={8} p={4}>
                        <Flex gap={8}>
                            <FormControl isRequired>
                                <FormLabel>Nom</FormLabel>
                                <Input variant='filled' placeholder='Product name' value={name} onChange={handleNameChange} />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Prix</FormLabel>
                                <NumberInput size='md' maxW='lg' onChange={handlePriceChange} value={price} placeholder='Product price'>
                                    <NumberInputField />
                                </NumberInput>
                            </FormControl>
                        </Flex>
                        <Flex gap={8}>
                            <FormControl isRequired>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    placeholder='Product description'
                                    size='sm'
                                    variant='filled'
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Avantage</FormLabel>
                                <Textarea
                                    value={advantage}
                                    onChange={handleAvantageChange}
                                    placeholder='Product advantages'
                                    size='sm'
                                    variant='filled'
                                />
                            </FormControl>
                        </Flex>
                        <Flex gap={8}>
                            <FormControl isRequired>
                                <FormLabel>Lien</FormLabel>
                                <Textarea
                                    value={link}
                                    onChange={handleLinkChange}
                                    placeholder='Product link'
                                    size='sm'
                                    variant='filled'
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Image de prévisualisation</FormLabel>

                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {previewFile && !isDragActive ? (
                                        <img src={previewFile} alt="Upload preview" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                    ) : (
                                        <img src={uploadImage} alt="DropzoneImage" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                    )}
                                </div>
                            </FormControl>
                        </Flex>
                    </Flex>

                </ModalBody>

                <ModalFooter>
                    <Flex gap={4}>
                        <Button onClick={() => onClose()} colorScheme='red' variant='solid' >
                            Annuler
                        </Button>
                        <Button onClick={productData?._id ? handleUpdateProduct : handleAddProduct} colorScheme='green' variant='solid' isLoading={loading} loadingText='En cours...'>
                            {productData?._id ? 'Modifier' : 'Ajouter'}
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddProductForm;