import React from 'react'

import TableTopCreators from "./TableTopCreators";
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';

const TransactionsOverview = (
    { transactions, loading }
) => {
    const textColor = useColorModeValue("navy.700", "white");
    return (
        <div>
            {transactions.length === 0 ? (
                <Flex direction='column' justifyContent='center' align='center' height={{ sm: "10rem", lg: "30rem" }}>
                    <Image src="https://bibemella.isomora.com/wp-content/uploads/2023/10/logo-fbe.png" width={50} height={50} mb={4} />
                    <Text color={textColor} fontSize='sm'>Aucunes transactions disponibles</Text>
                </Flex>
            ) : (
                <TableTopCreators
                    transactions={transactions}
                    loading={loading}
                />
            )}

        </div>
    )
}

export default TransactionsOverview;
