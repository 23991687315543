import {
  Avatar,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import LogoOrange from '../../../../assets/img/CM_ORANGEMONEY.png'
import LogoMTN from '../../../../assets/img/CM_MTNMOBILEMONEY.png'
import Loading from "components/loading";
import { FaFileDownload } from "react-icons/fa";
import moment from 'moment/moment';
import Invoice from '../../../../components/invoice/Invoice';
import { pdf } from '@react-pdf/renderer';

function TopCreatorTable(props) {
  const { transactions, loading } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const generatePdf = async (index) => {
    const selectedData = transactions[index]

    const invoice = (

      <Invoice
        invoiceNumber={selectedData.transactionId || ''}
        operator={selectedData.operator || ''}
        purchaseDate={moment(selectedData.createdAt).format('dddd D MMMM YYYY [à] HH[h]mm')}
        username={selectedData.userData.name || ''}
        transactionNumber={selectedData.userNumber}
        itemName={selectedData.optionId || ''}
        unitPrice={selectedData.amount || 0}
        quantity={selectedData.productId?.quantity || 1}
        total={selectedData.amount || 0}
      />
    );

    const pdfBlob = await pdf(invoice).toBlob();
    const url = URL.createObjectURL(pdfBlob);
    const a = document.createElement('a'); 
    a.href = url;
    a.download = `Facture_${selectedData.userData.name}.pdf`;
    a.click();
  };

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        {loading ? <>
          <Loading />
        </> : <>
          <TableContainer >
            <Table size='sm' variant='simple' color='gray.500' mb='24px'>
              <Thead>
                <Tr>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Opérateur
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Numéro transaction
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Utilisateur
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Montant
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Id Transaction
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Produit acheté
                    </Flex>
                  </Th>
                  <Th borderColor={borderColor}>
                    <Flex
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      Statut
                    </Flex>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.map((transaction, index) => (
                  <Tr key={index}>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction.operator === 'Orange' ?
                        <Flex align='center'>
                          <Avatar size="sm" name={transaction.operator} src={LogoOrange} />
                          <Text color={textColor} fontSize='sm' fontWeight='700' ml={4}>
                            {transaction?.operator}
                          </Text>
                        </Flex> :
                        <Flex align='center'>
                          <Avatar size="sm" name={transaction.operator} src={LogoMTN} />
                          <Text color={textColor} fontSize='sm' fontWeight='700' ml={4}>
                            {transaction?.operator}
                          </Text>
                        </Flex>
                      }
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.transactionNumber}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.userData?.name}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.amount} XAF
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.transactionId.substring(0, 10) + "..."}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700' overflowWrap='normal'>
                      {transaction.isOption ? transaction.optionId.substring(0, 10) + "..." : transaction.productId.substring(0, 10) + "..."}
                    </Td>
                    <Td color={textColor} fontSize='sm' fontWeight='700'>
                      {transaction?.status}
                    </Td>
                    <Td>
                      <IconButton
                        aria-label='Call Segun'
                        size='md'
                        background='transparent'
                        icon={<FaFileDownload />}
                        onClick={() => generatePdf(index)}
                      // onClick={generatePdf}
                      // isDisabled={downloadClicked}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>}
      </Flex>
    </>
  );
}

export default TopCreatorTable;
