
export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_FAILED = 'GET_TRANSACTIONS_FAILED';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';

export const UPDATE_USERS_ROLE_REQUEST = 'UPDATE_USERS_ROLE_REQUEST';
export const UPDATE_USERS_ROLE_SUCCESS = 'UPDATE_USERS_ROLE_SUCCESS';
export const UPDATE_USERS_ROLE_FAILED = 'UPDATE_USERS_ROLE_FAILED';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const GET_STATS_REQUEST = 'GET_STATS_REQUEST';
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS';
export const GET_STATS_FAILED = 'GET_STATS_FAILED';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILED = 'ADD_PRODUCT_FAILED';

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAILED = 'ADD_SERVICE_FAILED';

export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILED = 'DELETE_PRODUCT_FAILED';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';

export const UPDATE_SERVICE_REQUEST = 'UPDATE_SERVICE_REQUEST';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAILED = 'UPDATE_SERVICE_FAILED';

export const DELETE_SUBSERVICE_REQUEST = 'DELETE_SUBSERVICE_REQUEST';
export const DELETE_SUBSERVICE_SUCCESS = 'DELETE_SUBSERVICE_SUCCESS';
export const DELETE_SUBSERVICE_FAILED = 'DELETE_SUBSERVICE_FAILED';

export const GET_TOTAL_AMOUNT_REQUEST = 'GET_TOTAL_AMOUNT_REQUEST';
export const GET_TOTAL_AMOUNT_SUCCESS = 'GET_TOTAL_AMOUNT_SUCCESS';
export const GET_TOTAL_AMOUNT_FAILED = 'GET_TOTAL_AMOUNT_FAILED';

export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAILED = 'GET_EVENTS_FAILED';

export const GET_EVENTS_USERS_REQUEST = 'GET_EVENTS_USERS_REQUEST';
export const GET_EVENTS_USERS_SUCCESS = 'GET_EVENTS_USERS_SUCCESS';
export const GET_EVENTS_USERS_FAILED = 'GET_EVENTS_USERS_FAILED';

export const ADD_EVENTS_REQUEST = 'ADD_EVENTS_REQUEST';
export const ADD_EVENTS_SUCCESS = 'ADD_EVENTS_SUCCESS';
export const ADD_EVENTS_FAILED = 'ADD_EVENTS_FAILED';

export const UPDATE_EVENTS_REQUEST = 'UPDATE_EVENTS_REQUEST';
export const UPDATE_EVENTS_SUCCESS = 'UPDATE_EVENTS_SUCCESS';
export const UPDATE_EVENTS_FAILED = 'UPDATE_EVENTS_FAILED';

export const DELETE_EVENTS_REQUEST = 'DELETE_EVENTS_REQUEST';
export const DELETE_EVENTS_SUCCESS = 'DELETE_EVENTS_SUCCESS';
export const DELETE_EVENTS_FAILED = 'DELETE_EVENTS_FAILED';

export const DELETE_IMAGE_GALLERY_REQUEST = 'DELETE_IMAGE_GALLERY_REQUEST';
export const DELETE_IMAGE_GALLERY_SUCCESS = 'DELETE_IMAGE_GALLERY_SUCCESS';
export const DELETE_IMAGE_GALLERY_FAILED = 'DELETE_IMAGE_GALLERY_FAILED';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
