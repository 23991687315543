import React, { useState } from "react";
// Chakra imports
import { Flex, Icon, IconButton, Image, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { FaEthereum } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import DeleteProduct from "./DeleteProduct";
import AddProductForm from "./AddProductForm";

export default function NFT(props) {
  const { image, name, price, product, onClick } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );

  const handleUpdateProduct = () => {
    setIsFormOpen(true);
  };

  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>

      <Flex direction={{ base: "column" }} justify='center' cursor="pointer" onClick={onClick}>
        <Flex position='relative' align='center'>
          <Image src={image} w='66px' h='66px' borderRadius='20px' me='16px' />
          <Flex
            direction='column'
            w={{ base: "70%", md: "75%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>
            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'>
              {name}
            </Text>
          </Flex>
          <Flex
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            align='center'>
            <Icon as={FaEthereum} color={textColor} width='9px' me='7px' />
            <Text fontWeight='700' fontSize='md' color={textColor}>
              {price} XAF
            </Text>
          </Flex>
          <Flex zIndex={100} opacity={isHovered ? 1 : 0} transition="opacity 1s ease-out" ml={4}>
            {isHovered && (
              <>
                <Flex align="center" gap={4}>
                  <IconButton
                    isRound={true}
                    variant='outline'
                    colorScheme='green'
                    aria-label='Done'
                    fontSize='20px'
                    icon={<EditIcon />}
                  onClick={handleUpdateProduct}
                  />
                  <DeleteProduct product={product?._id} />

                  <AddProductForm
                  isOpen={isFormOpen}
                  onClose={() => setIsFormOpen(false)}
                  productData={product}
                />
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
