
import * as types from './types';

export const fetchTransactionsRequest = payload => ({
  type: types.GET_TRANSACTIONS_REQUEST,
  payload
});

export const fetchUsersRequest = payload => ({
  type: types.GET_USERS_REQUEST,
  payload
});

export const updateUsersRequest = (phoneNumber, updatedData) => ({
  type: types.UPDATE_USERS_ROLE_REQUEST,
  payload: { phoneNumber, updatedData },
});

export const fetchProductsRequest = payload => ({
  type: types.GET_PRODUCTS_REQUEST,
  payload
});

export const fetchStatsRequest = payload => ({
  type: types.GET_STATS_REQUEST,
  payload
});

export const addServiceRequest = (serviceData) => ({
  type: types.ADD_SERVICE_REQUEST,
  payload: serviceData,
});

export const addProductRequest = (productData) => ({
  type: types.ADD_PRODUCT_REQUEST,
  payload: productData,
});

export const deleteProductRequest = (id) => ({
  type: types.DELETE_PRODUCT_REQUEST,
  payload: {id},
});

export const updateProductRequest = (id, productData) => ({
  type: types.UPDATE_PRODUCT_REQUEST,
  payload: {id, productData},
});

export const updateServiceRequest = (id, serviceData) => ({
  type: types.UPDATE_SERVICE_REQUEST,
  payload: {id, serviceData},
});

export const deleteSubserviceRequest = (payload) => ({
  type: types.DELETE_SUBSERVICE_REQUEST,
  payload,
});

export const fetchTotalAmountRequest = payload => ({
  type: types.GET_TOTAL_AMOUNT_REQUEST,
  payload
});

export const fetchEventsRequest = payload => ({
  type: types.GET_EVENTS_REQUEST,
  payload
});

export const fetchEventsUsersRequest = (payload) => ({
  type: types.GET_EVENTS_USERS_REQUEST,
  payload
});

export const addEventsRequest = (eventData) => ({
  type: types.ADD_EVENTS_REQUEST,
  payload: eventData,
});

export const updateEventsRequest = (id, eventData) => ({
  type: types.UPDATE_EVENTS_REQUEST,
  payload: {id, eventData},
});

export const deleteEventsRequest = (id) => ({
  type: types.DELETE_EVENTS_REQUEST,
  payload: {id},
});

export const deleteImageGalleryRequest = (payload) => ({
  type: types.DELETE_IMAGE_GALLERY_REQUEST,
  payload,
});

export const loginRequest = (phoneNumber, password) => ({
  type: types.LOGIN_REQUEST,
  payload: {phoneNumber, password},
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS,
});

