import { all } from 'redux-saga/effects';
import DashboardSaga from 'redux/sagas';

/**
 * @description combine sagas
 */
export default function* Sagas() {
  yield all([
    DashboardSaga(),
  ]);
}
