import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdOutlineSettingsSuggest,
  MdHome,
  MdOutlineShoppingCart,
  MdEvent,
} from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { BiTransferAlt } from "react-icons/bi";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Users from "views/admin/users"
import Transactions from "views/admin/transactions";
import Evenements from "views/admin/evenements";
import Configurations from "views/admin/configurations";
import SignIn from "views/auth/signIn";


const routes = [
  {
    name: "Tableau de bord",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Transactions",
    layout: "/admin",
    icon: <Icon as={BiTransferAlt} width='20px' height='20px' color='inherit' />,
    path: "/transactions-list",
    component: Transactions,
},
  {
    name: "Utilisateurs",
    layout: "/admin",
    icon: <Icon as={FaUsers} width='20px' height='20px' color='inherit' />,
    path: "/users-list",
    component: Users,
  },
  {
    name: "Produits et services",
    layout: "/admin",
    path: "/produits-services",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: NFTMarketplace,
    secondary: true,
  },
  {
    name: "Evènements",
    layout: "/admin",
    path: "/evenement",
    icon: (
      <Icon
        as={MdEvent}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Evenements,
    secondary: true,
  },
  {
    name: "Configurations",
    layout: "/admin",
    path: "/configuration",
    icon: (
      <Icon
        as={MdOutlineSettingsSuggest}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Configurations,
    secondary: true,
  },
  {
    name: "Login",
    layout: "/auth",
    path: "/login",
    icon: (
      <Icon
        as={MdEvent}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: SignIn,
    secondary: true,
  },
];

export default routes;
