import { combineReducers } from 'redux';
import DashboardReducer from 'redux/reducers';

/**
 * @description combine reducers
 */
const rootReducer = combineReducers({
  DashboardReducer,
});

export default rootReducer;
